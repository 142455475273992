<template>
  <div>
    <h2 class="hline">Настройки</h2>
    <hr>
    <div class="settings">
      <div class="pass">
        <span class="viewH">Изменение пароля учетной записи:</span>
        <div class="old-pass">
          <v-text-field
            class="pass-input"
            :label="passMess"
            outlined
            dense
            v-model="pass.oldPass"
            type="password"
          ></v-text-field>
        </div>
        <div class="new-pass">
          <v-text-field
            class="pass-input"
            label="Введите новый пароль"
            outlined
            dense
            :disabled="!pass.oldPass"
            v-model="pass.newPass"
            type="password"
          ></v-text-field>
          <v-text-field
            class="pass-input"
            label="Введите пароль еще раз"
            outlined
            dense
            :disabled="!pass.oldPass"
            v-model="pass.repeatNewPass"
            type="password"
          ></v-text-field>
          <div class="pass-btn">
            <v-btn small color="red" @click="cancel">Отмена</v-btn>
            <v-btn 
              small 
              color="green" 
              @click="_updPass" 
              :disabled="pass.newPass && pass.repeatNewPass && pass.newPass == pass.repeatNewPass ? false : true"
            >Изменить</v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="logo-wrapper">
      <span>Логотип:</span>
        <div class="wrap">
          <v-img
            class="logoIMG"
            :src="itemIMG"
          ></v-img>
        </div>
        <v-file-input
          v-if="access.itemIMG"
          class="fileInput"
          label="выберите изображение..."
          outlined
          dense
          show-size
          counter
          prepend-icon="mdi-camera"
          v-model="logoIMG"
          @change="logoImgInput()"
        ></v-file-input>
        <v-btn 
          :class="{imgMargin: !access.itemIMG}"
          v-if="!access.itemIMG"
          @click="access.itemIMG = true"
          small
        >изменить</v-btn>
        <v-btn small @click="itemIMGSave()" v-if="access.itemIMG" color="green" :disabled="logoIMG == null">Сохранить</v-btn>
        <v-btn small @click="logoIMGCancel()" v-if="access.itemIMG" color="red">отмена</v-btn>
      </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function
  },
  data: () => ({
    access: {
      itemName: false,
      itemIMG: false,
      prodSaveBtn: false
    },
    passMess: 'Введите текущий пароль',
    pass: {},
    itemIMG: '',
    logoIMG: null
  }),
  computed: {
    ...mapGetters(['getLogo']),
  },
  methods: {
    ...mapActions(['updatePass', 'logoUpdate']),
    async _updPass() {
      this.loadingWindow(true)
      this.pass.id = this.$store.getters.getUserID
      let res = await this.updatePass(this.pass)
      this.loadingWindow(false)
      this.cancel()
      if(res.oldPass == false) this.messageOn('Неверный текущий пароль', 'red')
      if(res.updPass == false) this.messageOn('[ошибка:] Пароль не изменен', 'red')
      if(res.updPass == true) this.messageOn('Пароль изменен', 'green')
    },
    cancel() {
      this.pass = {}
    },
    logoImgInput() {
      this.logoIMG != null ? this.itemIMG = URL.createObjectURL(this.logoIMG) : this.itemIMG = ''
    },
    async itemIMGSave() {
      this.loadingWindow(true)
      let response = await this.logoUpdate({file: this.logoIMG})
      this.access.itemIMG = false
      this.logoIMG = null
      this.loadingWindow(false)
      if (response.logoUpdate == true) {
        this.messageOn('Изображение сохранено', 'green')
      } else {
        this.messageOn('[ошибка:] Ошибка сохранения!', 'red')
      }
      this.logoIMGCancel()
    },
    logoIMGCancel() {
      this.access.itemIMG = false
      this.logoIMG = null
      this.itemIMG = this.getLogo + '?' + Date.now()
    }
  },
  mounted: function() {
    this.itemIMG = this.getLogo
  }
}
</script>

<style lang="scss" scoped>
  .settings {
    margin-top: 25px;
    .pass {
      .old-pass {
        margin-top: 15px;
      }
      .pass-input {
        max-width: 300px;
      }
      .pass-btn {
        button {
          &:nth-child(2) {
            margin: 0 5px;
          }
        }
      }
    }
  }
  .logo-wrapper {
    margin-top: 50px;
    span {
      display: block;
      padding: 10px 0;
    }
      .wrap {
        border: 1px solid #ffffff;
        padding: 5px;
        border-radius: 3px;
        max-width: 170px;
        .logoIMG {
          width: 100%;
          border-radius: 3px;
        }
      }
      .fileInput {
        max-width: 500px;
        margin: 19px 0 0 0;
      }
      button {
        margin: -7px 10px 0 0;
      }
      .imgMargin {
        margin-top: 15px;
      }
    }
</style>
